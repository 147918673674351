import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import axiosOriginal from 'axios';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import {
  Friend,
  Gallery,
  Profile,
  UserPost,
  Follower,
  UserData,
  CreditCard,
  UserInvoice,
  UserManager,
  UserAddressBook,
  NotificationSettings
} from '../../@types/user';
import { Company } from '../../@types/company';

// ----------------------------------------------------------------------

type UserState = {
  isLoading: boolean;
  error: boolean;
  profile: any;
  companyUsers: any[],
  company: Company,
  todaysVisits: any[],
  stats: any,
  myProfile: null | Profile;
  posts: UserPost[];
  users: UserData[];
  userList: UserManager[];
  followers: Follower[];
  friends: Friend[];
  gallery: Gallery[];
  cards: CreditCard[] | null;
  addressBook: UserAddressBook[];
  invoices: UserInvoice[];
  notifications: NotificationSettings | null;
};

const initialState: UserState = {
  isLoading: false,
  error: false,
  profile: null,
  companyUsers: [],
  company: {
    id: '',
    name: '',
    email: '',
    country: '',
    address: '',
    state: '',
    city: '',
    zipCode: '',
    phone: '',
    code: '',
    alphanumericIdSms: '',
    photoUrl: '',
    remindEarly: false,
    remindLate: false
  },
  todaysVisits: [],
  stats: null,
  myProfile: null,
  posts: [],
  users: [],
  userList: [],
  followers: [],
  friends: [],
  gallery: [],
  cards: null,
  addressBook: [],
  invoices: [],
  notifications: null
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    // GET USER PROFILE
    getUsersProfileSuccess(state, action) {
      state.isLoading = false;
      state.profile = action.payload;
    },

    // UPDATE USER
    updateUserSuccess(state, action) {
      state.isLoading = false;
      state.profile = action.payload;
    },

    // GET COMPANY USERS
    getCompanyUsersSuccess(state, action) {
      state.isLoading = false;
      state.companyUsers = action.payload;
    },

    // GET COMPANY
    getCompanySuccess(state, action) {
      state.isLoading = false;
      state.company = action.payload;
    },

    // UPDATE COMPANY
    updateCompanySuccess(state, action) {
      state.isLoading = false;
      state.company = action.payload;
    },

    // GET TODAY'S VISITS FOR THIS USER
    getTodaysVisitsSuccess(state, action) {
      state.isLoading = false;
      state.todaysVisits = action.payload;
    },

    // GET STATS
    getStatsSuccess(state, action) {
      state.isLoading = false;
      state.stats = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // GET FOLLOWERS
    getFollowersSuccess(state, action) {
      state.isLoading = false;
      state.followers = action.payload;
    },

    // ON TOGGLE FOLLOW
    onToggleFollow(state, action) {
      const followerId = action.payload;

      const handleToggle = map(state.followers, (follower) => {
        if (follower.id === followerId) {
          return {
            ...follower,
            isFollowed: !follower.isFollowed
          };
        }
        return follower;
      });

      state.followers = handleToggle;
    },

    // GET FRIENDS
    getFriendsSuccess(state, action) {
      state.isLoading = false;
      state.friends = action.payload;
    },

    // GET GALLERY
    getGallerySuccess(state, action) {
      state.isLoading = false;
      state.gallery = action.payload;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    // GET CARDS
    getCardsSuccess(state, action) {
      state.isLoading = false;
      state.cards = action.payload;
    },

    // GET ADDRESS BOOK
    getAddressBookSuccess(state, action) {
      state.isLoading = false;
      state.addressBook = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow } = slice.actions;


// ----------------------------------------------------------------------

export function getUserProfile(uid: string, token: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };
    const reqString = `${process.env.REACT_APP_API}/api/user/uid/${uid}`;
    try {
      const response = await axiosOriginal.get(reqString, requestOptions);
      dispatch(slice.actions.getUsersProfileSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// export function updateUserRole(values: any, token: any) {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const headers = {
//         'Content-Type': 'application/json',
//         Authorization: `Bearer ${token}`
//       };
//       const reqString = `${process.env.REACT_APP_API}/api/user`;
//       const response = await axiosOriginal.put(reqString, JSON.stringify(values), { headers });
//       dispatch(slice.actions.updateUserRoleSuccess(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------

export function getCompanyUsers(id: string, token: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };
    const reqString = `${process.env.REACT_APP_API}/api/users`;
    try {
      const response = await axiosOriginal.get(reqString, requestOptions);
      dispatch(slice.actions.getCompanyUsersSuccess(response.data.content));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCompany(token: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };
    const reqString = `${process.env.REACT_APP_API}/api/company`;
    try {
      const response = await axiosOriginal.get(reqString, requestOptions);
      dispatch(slice.actions.getCompanySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateCompany(
  id: any,
  values: any,
  token: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      };
      const reqString = `${process.env.REACT_APP_API}/api/company/${encodeURIComponent(id)}`;
      const response = await axiosOriginal.put(reqString, JSON.stringify(values), { headers });
      dispatch(slice.actions.updateCompanySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export async function updateUser(values: any, token: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };
    const reqString = `${process.env.REACT_APP_API}/api/users`;
    try {
      const response = await axiosOriginal.put(reqString, requestOptions, values);
      dispatch(slice.actions.updateUserSuccess(response.data.content));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTodaysVisits(token: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };
    const reqString = `${process.env.REACT_APP_API}/api/user/visits/today`;
    try {
      const response = await axiosOriginal.get(reqString, requestOptions);
      dispatch(slice.actions.getTodaysVisitsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getStats(id: string, token: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const requestOptions = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };
    const reqString = `${process.env.REACT_APP_API}/api/stats`;
    try {
      const response = await axiosOriginal.get(reqString, requestOptions);
      dispatch(slice.actions.getStatsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProfile() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/profile');
      dispatch(slice.actions.getProfileSuccess(response.data.profile));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPosts() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/posts');
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFollowers() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/followers');
      dispatch(slice.actions.getFollowersSuccess(response.data.followers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFriends() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/friends');
      dispatch(slice.actions.getFriendsSuccess(response.data.friends));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGallery() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/gallery');
      dispatch(slice.actions.getGallerySuccess(response.data.gallery));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/manage-users');
      dispatch(slice.actions.getUserListSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCards() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/cards');
      dispatch(slice.actions.getCardsSuccess(response.data.cards));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAddressBook() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/address-book');
      dispatch(slice.actions.getAddressBookSuccess(response.data.addressBook));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoices() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/invoices');
      dispatch(slice.actions.getInvoicesSuccess(response.data.invoices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNotifications() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        '/api/user/account/notifications-settings'
      );
      dispatch(
        slice.actions.getNotificationsSuccess(response.data.notifications)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUsers() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/all');
      dispatch(slice.actions.getUsersSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
