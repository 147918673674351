export const NOWA_SZATA_GRAFICZNA_BODY = `


<h3>Nowa szata graficzna</h3><br/>

<hr>
<h4>inMedi</h4><br/>
<p>
Platforma dostarcza znaczną wartość dla placówek medycznych w postaci oszczędności kosztów operacyjnych związanych 
z zapobieganiem niepojawiania się pacjentów na umówionych wizytach, oszczędność czasu dla pracowników rejestracji jak 
i lekarzy, oraz poprawę wizerunku pracówki dzięki licznym funkcjom platformy.<br/>
Dziękujemy za wsparcie i zapraszamy do korzystania z nowych funkcji.
</p>
<hr>
<h4>Nadchodzące wydania</h4><br/>

<u>Moduł wizyty po stronie placówki medycznej</u> będzie łatwo instalowalnym modułem platformy po stronie placówki medycznej.
<ol>
<li>Na stronie internetowej placówki pojawi się przycisk <em>Umów wizytę</em> który odsyła do podstrony wizyty.państwa-placwóka.pl.
Tam klient ma możliwość wybrania typu wizyty i najbliższego wolnego terminu, a następnie zarezerwowania wizyty.</li>
<li>Placówka dostaje powiadomienia o umówionych w ten sposób wizytach i może nimi swobodnie zarządzać. Klient dostaje informacje oraz
przypomnienia o wizycie w dotychczasowy sposób.</li>
<li>Placówka medyczna może w dotychczasowym terminarzu określić które kalendarze oraz wydarzenia są publiczne, a które prywatne.</li>
</ol>
`;
