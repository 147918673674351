import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
// layouts
import HomeLayout from '../layouts/home';
import { PATH_DASHBOARD, PATH_HOME, PATH_PAGE } from './paths';

// ----------------------------------------------------------------------

const HomeRoutes = {
  path: '*',
  layout: HomeLayout,
  routes: [
    {
      exact: true,
      path: '/',
      component: lazy(() => import('../views/LandingPage'))
    },
    {
      exact: true,
      path: PATH_HOME.contact,
      component: lazy(() => import('../views/Contact'))
    },
    {
      exact: true,
      path: PATH_HOME.why,
      component: lazy(() => import('../views/Why'))
    },
    {
      exact: true,
      path: PATH_HOME.pricing,
      component: lazy(() => import('../views/Pricing'))
    },
    // {
    //   exact: true,
    //   path: '/components',
    //   component: lazy(() => import('../views/ComponentsOverview'))
    // },

    // ----------------------------------------------------------------------

    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default HomeRoutes;
